<!-- 日志 -->
<template>
	<div id="log">
		<p v-for="i of 10">我是日志测试测试</p>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style lang="scss">
</style>
